const scienceAccordionData = [
  {
    title: "AIM Hybrid Work",
    desc: "Our Autonomy in Isolation Model (AIM) assesses how employees handle physical separation in an increasingly remote world. Use intelligence to drive support.",
    contentImg: "aim",
  },
  {
    title: "DAAP model",
    desc: "The Desire and Ability to Attend and Perform model underpins SHAPE. DAAP is invisibly inherent in all aspects of SHAPE - all Explorers, all the questions within them, impact one or more areas of DAAP.",
    contentImg: "daap",
  },
  {
    title: "DASS for Mental Health",
    desc: "SHAPE addresses the impact of mental health on work. We’ve pioneered the Depression, Anxiety and Stress Scale to just 9 questions. Its accuracy powers direct signposting to intervention services.",
    contentImg: "dass",
  },
  {
    title: "Explorer Models",
    desc: "From Corporate Culture’s 25 sub-factor model to Employee Engagement and Business Essential’s 10 factor models, every Explorer topic is powered by scientifically validated models packed with statistical accuracy, scoring technology and efficacy assurance.",
    contentImg: "explorer",
  },
  {
    title: "Power of Scale",
    desc: "SHAPE is designed for scale. From small companies with 25 employees to the world’s largest multinationals with teams of any size imaginable. Every aspect of the platform scales to automatically survey, report and guide.",
    contentImg: "power",
  },
  {
    title: "SHAPE ProScore",
    desc: "Flourishing is the ultimate new goal made possible by ProScore. A truly advanced measurement made simple, ProScore blends statistical and behavioural sciences to accurately inform where individuals, teams and the company are struggling, striving or flourishing.",
    contentImg: "proscore",
  },

  {
    title: "Time to Gain",
    desc: "Each SHAPE point has tangible value. Investment to improve scores translates into tangible company benefits. Understand the time and money ROI values to be gained through positive action.",
    contentImg: "ea",
  },
  {
    title: "Your Network",
    desc: "Build your organisational structure from the ground up, one employee at a time. Integrated into the survey, it takes literally less than 1 minute. Welcome to the world’s most accurate team reporting while maintaining privacy.",
    contentImg: "yn",
  },
]

export { scienceAccordionData }
