import React, { useRef, useEffect } from "react"
import Link from "gatsby-link"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ShapedScience from "../components/shaped-science/shaped-science"
import FlourishingCta from "../components/flourishing-cta/flourishing-cta"
import "./science.scss"
import { ScienceBehindShape } from "../components/science-shape/science-shape"
import { FlourishingSystem } from "../components/flourishingSystem/flourishingSystem"
import { FlourishingWheel } from "../components/flourishing-wheel/flourishing-wheel"

const Science = () => {
  return (
    <Layout>
      <div className="scienceWrap">
        <SEO title="Science" keywords={[`gatsby`, `application`, `react`]} />
        <ScienceBehindShape />
        <FlourishingSystem />
        <FlourishingCta page={"science"} />
        <FlourishingWheel />
        <ShapedScience />
      </div>
    </Layout>
  )
}

export default Science
