import React, { useEffect, useRef, useState } from "react"
import "./shaped-science.scss"
import { Accordion, Card, Button } from "react-bootstrap"
import { scienceAccordionData } from "./helper"
import GeneralCarousel from "../general-carousel/general-carousel"
import Aim from "../../images/home/shapeScience/aim.svg"
import Daap from "../../images/home/shapeScience/daap.svg"
import Daas from "../../images/home/shapeScience/dass.svg"
import Ea from "../../images/home/shapeScience/ea.svg" //.svg
import EaIos from "../../images/home/shapeScience/ea2.png" //.svg
import Explorer from "../../images/home/shapeScience/explorer.svg" //.svg
import Power from "../../images/home/shapeScience/power.svg"
import ProScore from "../../images/home/shapeScience/proscore.svg"
import Yn from "../../images/home/shapeScience/yn.svg"
import { isSafari } from "react-device-detect"

const ShapedScience = () => {
  const [isSafariBrow, setIsSafari] = useState(false)
  useEffect(() => {
    setIsSafari(isSafari)
  }, [isSafari])
  const ImagesMap = {
    daap: Daap,
    dass: Daas,
    yn: Yn,
    aim: Aim,
    ea: isSafariBrow ? EaIos : Ea,
    proscore: ProScore,
    explorer: Explorer,
    power: Power,
  }
  const imgSrc = useRef(null)
  const setImage = (name) => {
    let ImgElem = ImagesMap[name]
    imgSrc.current.src = ImgElem
  }
  useEffect(() => {
    if (!imgSrc.current) return
    imgSrc.current.src = ImagesMap[scienceAccordionData[0].contentImg]
  }, [])

  return (
    <div className="scienceWrapper">
      <div className="scienceContentWrapper">
        <h1 className="scienceHeading">The Models Behind SHAPE</h1>

        <>
          <div className="scienceAccordionFlex scienceDesktop">
            <div className="scienceAccordionWrapper">
              <Accordion defaultActiveKey="0">
                {scienceAccordionData?.map((acc, index) => (
                  <div
                    className="scienceFaqItem"
                    key={`accordion-${acc.title}-${index}`}
                  >
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey={index.toString()}
                      onClick={() => setImage(acc.contentImg)}
                    >
                      <span className="scienceSubHeading">{acc.title}</span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={index.toString()}>
                      <Card.Body className="scienceAnswer">
                        {acc.desc}{" "}
                      </Card.Body>
                    </Accordion.Collapse>
                  </div>
                ))}
              </Accordion>
            </div>
            <div className="scienceImgWrapper">
              <img alt="science-img" ref={imgSrc} />
            </div>
          </div>
        </>
        <div className="scienceResponsive">
          <GeneralCarousel
            shapeScience={true}
            data={scienceAccordionData}
            map={ImagesMap}
          />
        </div>
      </div>
    </div>
  )
}

export default ShapedScience
