import React, { useState } from "react"
import "./explorer-view.scss"
import PW from "../../images/explorer-view/pw-exp.svg"
import PHY from "../../images/explorer-view/phy-exp.svg"
import PSY from "../../images/explorer-view/psy-exp.svg"
import FOW from "../../images/explorer-view/fow-exp.svg"
import PWE from "../../images/explorer-view/pwe-exp.svg"
import SWE from "../../images/explorer-view/swe-exp.svg"
import BF from "../../images/explorer-view/bf-exp4.svg"
import CC from "../../images/explorer-view/cc-exp.svg"
import MS from "../../images/explorer-view/ms-exp.svg"
import JS from "../../images/explorer-view/js-exp.svg"
import Coworker from "../../images/explorer-view/cowork.svg"
import EE from "../../images/explorer-view/ee-exp.svg"
import EC from "../../images/explorer-view/ec-exp.svg"
import Logo from "../../images/board-logo.svg"
import Person from "../../images/home/howShapeWorks/personIcon.svg"

const ExplorerView = (servicePage) => {
  const [currentExplorer, setCurrentExplorer] = useState(null)

  const mouseEnterImage = (e) => {
    let nextExplorer = e.target.alt
    let otherExplorer
    if (nextExplorer !== currentExplorer) {
      let contentText = document.getElementById("explorer-content")
      contentText.style.opacity = 0
      contentText.style.transform = "translateY(5px)"

      setTimeout(() => {
        // setCurrentPage(index)
        setCurrentExplorer(nextExplorer)
        contentText.style.opacity = 1
        contentText.style.transform = "translateY(-5px)"
      }, 300)
    }
    for (let i = 1; i <= 12; i++) {
      if (e.target.id !== `explorer-view-${i}`) {
        otherExplorer = document.getElementById(`explorer-view-${i}`)
        otherExplorer.style.opacity = 0.6
      }
    }
  }
  const mouseLeaveImage = (e) => {
    let otherExplorer

    for (let i = 1; i <= 12; i++) {
      otherExplorer = document.getElementById(`explorer-view-${i}`)
      otherExplorer.style.opacity = 1
    }
  }

  const onResetExplorer = () => {
    if (currentExplorer !== null) {
      let contentText = document.getElementById("explorer-content")
      contentText.style.opacity = 0
      contentText.style.transform = "translateY(5px)"

      setTimeout(() => {
        // setCurrentPage(index)
        setCurrentExplorer(null)
        contentText.style.opacity = 1
        contentText.style.transform = "translateY(-5px)"
      }, 300)
    }
  }
  const explorersDetails = {
    pw: {
      heading: "Job Satisfaction",
      desc: "Pleased, or frustrated?",
      module: "ABOUT YOU",
      img: JS,
    },
    phy: {
      heading: "Physical Health",
      desc: "Optimal, or poor?",
      module: "ABOUT YOU",
      img: PHY,
    },
    psy: {
      heading: "Psychological Health",
      desc: "Ideal, or struggling?",
      module: "ABOUT YOU",
      img: PSY,
    },
    fow: {
      heading: "Life Outside Work",
      desc: "In balance, ​or imbalanced?",
      module: "ABOUT YOU",
      img: FOW,
    },
    pwe: {
      heading: "Physical Work Environment",
      desc: "Helpful, or hindering?",
      module: "THE FACILITIES",
      img: PWE,
    },
    swe: {
      heading: "Soft Work Environment",
      desc: "Enabling, or disabling?",
      module: "THE FACILITIES",
      img: SWE,
    },
    bf: {
      heading: "Business Essentials",
      desc: "Optimised, or sub-par?",
      module: "THE COMPANY",
      img: BF,
    },
    cc: {
      heading: "Corporate Culture",
      desc: "Inspirational, or toxic?",
      module: "THE COMPANY",
      img: CC,
    },
    ms: {
      heading: "Manager Style",
      desc: "Supportive, or obstructive?",
      module: "THE PEOPLE",
      img: MS,
    },
    js: {
      heading: "Coworker Dynamic",
      desc: "Strong, or weak?",
      module: "THE PEOPLE",
      img: Coworker,
    },
    ee: {
      heading: "Employee Engagement",
      desc: "Engaged, or disengaged?",
      module: "THE PEOPLE",
      img: EE,
    },
    ec: {
      heading: "Employee Commitment",
      desc: "Dedicated, or indifferent?",
      module: "THE PEOPLE",
      img: EC,
    },
  }
  return (
    <div className={`explorer-view-wrapper`} id="explorer-view">
      <h1>The Flourishing Wheel</h1>
      <p className="exploreview-description">
        The fundamentals of measuring flourishing.
      </p>
      <div className="explorer-view-contentWrapper">
        <div id="explorer-content" className="explorer-view-content">
          <div className="explorer-view-subwrapper">
            {!currentExplorer && <h2>Explore the 12 Explorers</h2>}
            <div className="cssanimation explorerBorderedContent">
              {currentExplorer ? (
                <div className="cssanimation">
                  <span
                    className={`${explorersDetails[currentExplorer].module
                      .replace(" ", "")
                      .toLowerCase()}`}
                  >
                    {explorersDetails[currentExplorer].module
                      ?.replace("THE", "")
                      .replace("ABOUT", "")}
                  </span>
                  <h3>{explorersDetails[currentExplorer].heading}</h3>
                  <p>{explorersDetails[currentExplorer].desc}</p>
                </div>
              ) : (
                <div className="cssanimation">
                  <h3 className="explorer-brand-heading">
                    All the intelligence you need to flourish, in one place.
                  </h3>
                  <p>Tap the Explorers to find out more.</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="explorer-view-svg">
          <div className="explorer-view-svg-wrapper fadeIn cssanimation">
            <div id="explorer-view-left" className="explorer-view-left">
              <div className="shape-logo-wrapper">
                <img
                  src={Logo}
                  alt="shape-logo"
                  id="explorer-view-logo"
                  onMouseOver={(e) => onResetExplorer()}
                />
              </div>
              <img
                src={JS}
                alt="pw"
                className="explorer-1"
                id="explorer-view-1"
                onMouseOver={(e) => mouseEnterImage(e)}
                onMouseLeave={(e) => mouseLeaveImage(e)}
              />
              <img
                src={PHY}
                alt="phy"
                className="explorer-2"
                id="explorer-view-2"
                onMouseOver={(e) => mouseEnterImage(e)}
                onMouseLeave={(e) => mouseLeaveImage(e)}
              />
              <img
                src={PSY}
                alt="psy"
                id="explorer-view-3"
                className="explorer-3"
                onMouseOver={(e) => mouseEnterImage(e)}
                onMouseLeave={(e) => mouseLeaveImage(e)}
              />
              <img
                src={FOW}
                alt="fow"
                id="explorer-view-4"
                className="explorer-4"
                onMouseOver={(e) => mouseEnterImage(e)}
                onMouseLeave={(e) => mouseLeaveImage(e)}
              />
              <img
                src={PWE}
                alt="pwe"
                id="explorer-view-5"
                className="explorer-5"
                onMouseOver={(e) => mouseEnterImage(e)}
                onMouseLeave={(e) => mouseLeaveImage(e)}
              />
              <img
                src={SWE}
                id="explorer-view-6"
                alt="swe"
                className="explorer-6"
                onMouseOver={(e) => mouseEnterImage(e)}
                onMouseLeave={(e) => mouseLeaveImage(e)}
              />
            </div>
            <div id="explorer-view-right" className="explorer-view-right">
              <img
                src={EC}
                alt="ec"
                id="explorer-view-12"
                className="explorer-12"
                onMouseOver={(e) => mouseEnterImage(e)}
                onMouseLeave={(e) => mouseLeaveImage(e)}
              />
              <img
                src={EE}
                alt="ee"
                id="explorer-view-11"
                className="explorer-11"
                onMouseOver={(e) => mouseEnterImage(e)}
                onMouseLeave={(e) => mouseLeaveImage(e)}
              />
              <img
                src={Coworker}
                alt="js"
                id="explorer-view-10"
                className="explorer-10"
                onMouseOver={(e) => mouseEnterImage(e)}
                onMouseLeave={(e) => mouseLeaveImage(e)}
              />
              <img
                src={MS}
                alt="ms"
                id="explorer-view-9"
                className="explorer-9"
                onMouseOver={(e) => mouseEnterImage(e)}
                onMouseLeave={(e) => mouseLeaveImage(e)}
              />
              <img
                src={CC}
                alt="cc"
                id="explorer-view-8"
                className="explorer-8"
                onMouseOver={(e) => mouseEnterImage(e)}
                onMouseLeave={(e) => mouseLeaveImage(e)}
              />
              <img
                src={BF}
                alt="bf"
                id="explorer-view-7"
                className="explorer-7"
                onMouseOver={(e) => mouseEnterImage(e)}
                onMouseLeave={(e) => mouseLeaveImage(e)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ExplorerView
