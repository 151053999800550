import React from "react"
import "./flourishing-wheel.scss"
import ExplorerView from "../explorer-view/explorer-view"

export const FlourishingWheel = () => {
  return (
    <div className="flourishWheel">
      <ExplorerView />
    </div>
  )
}
