import React from "react"
import "./flourishingSystem.scss"
import FlourishVisual from "../../images/science/flourishSystem.svg"
import FlourishVisualMob from "../../images/science/flourishSystemMob.svg"
export const FlourishingSystem = () => {
  return (
    <div className="flourishSystem">
      <div className="flourishSystemWrapper">
        <h1>The Flourishing System</h1>
        <p>Empower everyone to get what they want.</p>
        <div className="flourishSystemContentFlex">
          <div className="flourishSystemContent">
            It starts with the individual building a flourishing mindset.
            <br />
            <br /> This continues to ripple through the organisation, with
            support from managers and executives.
            <br />
            <br />
            Everyone plays their part in creating the conditions that enhance
            flourishing and performance.
          </div>
          <div className="flourishSystemVisual">
            <img
              src={FlourishVisual}
              alt="Flourish-System"
              className="flourishSystemVisualDesktop"
            />
            <img
              src={FlourishVisualMob}
              alt="Flourish-System"
              className="flourishSystemVisualMob"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
