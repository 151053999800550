const getSettings = (right, left) => {
  let settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: right,
    prevArrow: left,
  }
  return settings
}

let data = [
  {
    heading:
      "<b>Guide employees to happiness and<br/> high-performance through a flourishing mindset.</b>",
    img: "Affects",
  },
  {
    heading: "<b>It's not just us saying this. Flourishing is... </b>",
    img: "Flourishing",
  },
  {
    heading: "<b>We found companies with the right approach flourish more.</b>",
    img: "Approach",
  },
  {
    heading:
      "<b>So we went to the science to help everyone access flourishing.</b>",
    img: "Access",
  },
  {
    heading: "<b>Turned discovery<br/>into innovation.</b>",
    img: "Discovery",
  },

  {
    heading:
      "<b>Then we made it faster.</b><br/><br/>Going alone, or working together, everyone gets to flourish faster. Literally!",
    img: "Faster",
  },
]

export { getSettings, data }
