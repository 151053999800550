import React from "react"
import "./science-shape.scss"
import { ScienceCard } from "./components/science-card"
import { data, getSettings } from "./helper"
import ArrowRight from "../../images/learnmore/arrowRight-light.svg"
import ArrowLeft from "../../images/learnmore/arrowLeft-light.svg"
import Slider from "react-slick"

export const ScienceBehindShape = () => {
  let settings = getSettings(
    <img src={ArrowRight} alt="next" style={{ right: "40px !important" }} />,
    <img src={ArrowLeft} alt="prev" style={{ left: "40px !important" }} />
  )

  return (
    <div className="behindShape">
      <div className="behindShapeContentWrap">
        <h1 className="behindShapeContentHeading">The Science behind SHAPE</h1>
        <div className="behindShapeShadowCard"></div>
        <div className="behindShapeContent">
          <Slider {...settings}>
            {data &&
              data?.map((card, index) => {
                return <ScienceCard card={card} />
              })}
          </Slider>
        </div>
      </div>
    </div>
  )
}
