import React from "react"
import "../science-shape.scss"
import Affects from "../../../images/science/behindShape/Affects2.svg"
import Faster from "../../../images/science/behindShape/faster.svg"
import Discovery from "../../../images/science/behindShape/discovery.svg"
import Access from "../../../images/science/behindShape/access.svg"
import Approach1 from "../../../images/science/behindShape/approach-1.svg"
import Approach1Mob from "../../../images/science/behindShape/approach-1Mob.svg"
import Approach2 from "../../../images/science/behindShape/approach-2.svg"
import Approach2Mob from "../../../images/science/behindShape/approach-2Mob.svg"
import Flourishing from "../../../images/science/behindShape/Flourishing.svg"

export const ScienceCard = ({ card }) => {
  let visualMap = {
    Affects: Affects,
    Faster: Faster,
    Discovery: Discovery,
    Access: Access,
    Approach1: Approach1,
    Approach2: Approach2,
    Flourishing: Flourishing,
  }
  let isAccess = card?.img === "Access"
  let isDiscovery = card?.img === "Discovery"
  let isFaster = card?.img === "Faster"
  return card?.img === "Approach" ? (
    <div className="behindShapeCard approach">
      <div className="behindScienceCardImg">
        <img
          className="desktop"
          src={visualMap["Approach2"]}
          alt={card?.img + "2"}
        />
        <img className="mobile" src={Approach2Mob} alt={card?.img + "2"} />
      </div>
      <h2 dangerouslySetInnerHTML={{ __html: card?.heading }} />
      <div className="behindScienceCardImg">
        <img
          className="desktop"
          src={visualMap["Approach1"]}
          alt={card?.img + "1"}
        />
        <img className="mobile" src={Approach1Mob} alt={card?.img + "1"} />
      </div>
    </div>
  ) : (
    <div
      className={`behindShapeCard ${isAccess ? "access" : ""} ${
        isDiscovery ? "discovery" : ""
      } ${isFaster ? "faster" : ""}`}
    >
      <h2 dangerouslySetInnerHTML={{ __html: card?.heading }} />
      <div className="behindShapeShadowCardMobile"></div>
      <div className="behindScienceCardImg">
        <img src={visualMap[card?.img]} alt={card?.img} />
      </div>
    </div>
  )
}
