import React, { useEffect, useState } from "react"
import "./general-carousel.scss"
import Tick from "../../images/home/address-challenges/Vector.svg"
const GeneralCarousel = ({ data, map, shapeScience }) => {
  const [currentPage, setCurrentPage] = useState(0)

  const onChangeCarousel = (e, index) => {
    let contentText = document.getElementById("card-content-text")
    let contentImg = document.getElementById("card-content-img")
    contentText.style.opacity = 0
    contentImg.style.opacity = 0

    setTimeout(() => {
      setCurrentPage(null)
      setCurrentPage(index)
      contentText.style.opacity = 1
      contentImg.style.opacity = 1
    }, 100)
  }

  return (
    <div
      className={`carouselWrapper ${
        shapeScience ? "shapeScienceCarousel" : ""
      }`}
    >
      <div className="carouselcardtabswrapper">
        <div className={`carouselcardtabs`}>
          {data.map((item, index) => (
            <div
              className={`${currentPage === index ? "fill" : ""}`}
              onClick={(e) => onChangeCarousel(e, index)}
            >
              {!shapeScience && currentPage === index && (
                <img src={Tick} alt="active-tab" />
              )}
              {item.title}
            </div>
          ))}
        </div>
      </div>
      <div className="carouselcardwrapper">
        <div id="carousel-card" className={`carouselcard `}>
          {data && currentPage !== null && currentPage >= 0 && (
            <div className="carouselcardcontent">
              <div
                id="card-content-text"
                className="carouselcardtext cssanimation"
              >
                {!shapeScience && (
                  <div className="carouselcardheading">
                    {data[currentPage].title}
                  </div>
                )}
                <div className="carouselcarddesc">{data[currentPage].desc}</div>
              </div>
              <div
                id="card-content-img"
                className={`carouselcardimg cssanimation`}
              >
                {!shapeScience &&
                !["Hybrid Balance"].includes(data[currentPage].title) ? (
                  <video autoPlay loop muted playsInline loading='lazy'>
                    <source
                      src={data[currentPage].contentImg}
                      type="video/mp4"
                    />
                    Sorry, your browser doesn't support videos.
                  </video>
                ) : (
                  <img
                    src={
                      shapeScience
                        ? map[data[currentPage].contentImg]
                        : data[currentPage].contentImg
                    }
                    alt={data[currentPage].title}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <div classNameName="carousel-wrapper-mobile">
        {data &&
          data.map((item, index) => (
            <div
              key={index}
              id="carousel-card-mobile"
              className={`carousel-card ${item.color}`}
            >
              <div className="carousel-card-content">
                <div
                  id="card-content-text"
                  className="carousel-card-text cssanimation"
                >
                  <div className="carousel-card-heading">{item.title}</div>
                  <div className="carousel-card-desc">{item.desc}</div>
                </div>
                <div
                  id="card-content-img"
                  className="carousel-card-img cssanimation"
                >
                  <img src={item.contentImg} alt={item.title} />
                </div>
              </div>
            </div>
          ))}
      </div> */}
    </div>
  )
}

export default GeneralCarousel
